<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>
          Relatório de treinamentos
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="nome">Treinamento</label>
          <select2 
            :ItemsProp="treinamentosFiltrado"
            :FieldProp="'nome_treinamento'"
            :PlaceholderTextProp="'Pesquisar treinamento'"
            :ClearAfterSelectedProp="false"
            @searchText="filteredTreinamentosData"
            :SelectedItemProp="treinamentoSelecionado"
            @Selected="TreinamentoSelecionado($event)">  
          </select2>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Empresa</label>
          <select2 
            :ItemsProp="empresas"
            :FieldProp="'nome_fantasia'"
            :PlaceholderTextProp="'Pesquisar empresa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="empresaSelecionada"
            @Selected="EmpresaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Pessoa</label>
          <select2 
            :ItemsProp="pessoasFiltrado"
            :FieldProp="'nome'"
            :PlaceholderTextProp="'Pesquisar pessoa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="pessoaSelecionada"
            @searchText="filteredFuncionariosData"
            @Selected="PessoaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="col-12 col-md-2 mr-2">
        <div class="form-group">
          <label class="required" for="">Data início</label>
          <input required v-model="filtro.data_inicio" placeholder="00/00/0000" v-mask="'##/##/####'" type="text" class="form-control">
        </div>
      </div>
      <div class="mr-2 col-12 col-md-2">
        <div class="form-group">
          <label class="required" for="">Data fim</label>
          <input required v-model="filtro.data_fim" v-mask="'##/##/####'" placeholder="00/00/0000" type="text" class="form-control">
        </div>
      </div>
      <div class="mr-5 align-self-end">        
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="gerarRelatorio">GERAR</button>
        </div>
      </div>
    </div>
    <div class="row mt-5"  v-if="dataItems.length > 0">
      <div class="col-12 mb-3 text-right">
        <ExportExcel :data="dataItems" :fields="excelFields" />
      </div>
      <div class="col-12">
        <table class="table table-hover border table-striped table-bordered" v-if="dataItems.length > 0">
          <thead>
            <td>Evento</td>
            <td>Nome Pessoa</td>
            <td>Cargo</td>
            <td>Empresa</td>
            <td>Treinamento</td>
            <td>Período</td>
            <td>Quant.Horas</td>
            <td>Resp. técnico</td>
            <td>Vencimento</td>
            <td>Observação</td>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataItems" :key="index">
              <td>{{item.evento}}</td>
              <td>{{item.funcionario_nome}}</td>
              <td>{{item.funcionario_cargo}}</td>
              <td>{{item.matriz_nome_fantasia}}</td>
              <td>{{item.nome_atividade}}</td>
              <td>{{item.data_inicio | maskdateptbr}} à {{item.data_fim | maskdateptbr}}</td>
              <td>
                <span>{{item.carga_horaria}}</span>
                <span v-if="item.carga_horaria_pratica"> ({{item.carga_horaria_pratica}}hs práticas</span> 
                <span v-if="item.carga_horaria_teorica && item.carga_horaria_pratica"> / </span>
                <span v-if="item.carga_horaria_teorica">{{item.carga_horaria_teorica}}hs teóricas)</span>
              </td>
              <td>{{item.nome_responsavel}}</td>
              <td>{{item.data_vencimento | maskdateptbr}}</td>
              <td>{{item.observacao}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="7">
                Total: {{dataItems.length}}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import treinamentoRepository from './../../../services/api/treinamentoRepository'
import relatoriosRepository from './../../../services/api/relatoriosRepository'
import empresaRepository from './../../../services/api/empresaRepository'
import funcionariosRepository from './../../../services/api/funcionariosRepository'
import select2 from './../../../uicomponents/select2'
import ExportExcel from './../../../uicomponents/ExportExcel'
import moment from 'moment'
export default {
  components: {
    select2,
    ExportExcel
  },
  data () {
    return {
      filtro: {
        data_inicio: '',
        data_fim: ''
      },
      isLoading: false,
      empresaSelecionada: null,
      pessoaSelecionada: null,
      treinamentoSelecionado: null,
      treinamentos: [],
      treinamentosFiltrado: [],
      // buscaTreinamentoLength: 0,
      empresas: [],
      pessoas: [],
      pessoasFiltrado: [],
      dataItems: [],
      excelFields: {
        'Evento': 'evento',
        'Nome Pessoa': 'funcionario_nome',
        Cargo: 'funcionario_cargo',
        Empresa: 'matriz_nome_fantasia',
        Treinamento: 'nome_atividade',
        Periodo: 'periodo',
        'Quant. Horas': 'carga_horaria',
        'Resp. técnico': 'nome_responsavel',
        Vencimento: {
          field: 'data_vencimento',
          callback: (value) => {
            return value.split('-').reverse().join('/')
          }
        },
        'Observação': 'observacao',
      }
    }
  },
  mounted() {
    this.filtro.data_fim = moment().format('DD/MM/YYYY')
    this.filtro.data_inicio = moment().format('DD/MM/YYYY')
    this.fetch()
  },
  methods: {
    filteredTreinamentosData (searchText) {
      let filterKey = searchText && searchText.length > 0 && searchText.toLowerCase()
      console.log(this.treinamentos)
      let data = [...this.treinamentos]
      
      if (filterKey) {
        data = data.filter(function (row) {
          if(String(row.codigo).indexOf(filterKey) > -1 || row.nome_atividade.toLowerCase().indexOf(filterKey) > -1) {
            return row
          }
        })
      }
      this.treinamentosFiltrado = [...data]
      console.log(this.treinamentosFiltrado)
      if(this.treinamentosFiltrado.length == 0) {
        this.listarTreinamentos(filterKey)
      }
      // this.buscaTreinamentoLength = searchText.length
    },
    filteredFuncionariosData (searchText) {
      let filterKey = searchText && searchText.length > 0 && searchText.toLowerCase()
      let data = [...this.pessoas]
      
      if (filterKey) {
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      this.pessoasFiltrado = [...data]
      if(this.pessoasFiltrado.length == 0) {
        this.listarFuncionarios(filterKey)
      }
      // this.buscaTreinamentoLength = searchText.length
    },
    listarFuncionarios(filterKey = '') {
      this.$store.commit("setSplashScreen", true)
      funcionariosRepository.listarFuncionarios(filterKey).then(response => {
        this.$store.commit("setSplashScreen", false)
        if(response.data.success) {
          this.pessoas = response.data.data
        }
      }).catch(error => {
        console.log(error)
        this.$store.commit("setSplashScreen", false)
      })
      //let responseF = await funcionariosRepository.listarFuncionariosFull()
      // if(responseF.data['success']) {
      //   this.pessoas = responseF.data['data']
      // }
    },
    async listarEmpresas() {
      this.isLoading = true
      let responseE = await empresaRepository.listarEmpresas()
      this.isLoading = false
      if(responseE.data['success']) {
        this.empresas = responseE.data['data']
      }
    },
    async listarTreinamentos(filterKey = '') {
      this.isLoading = true
      let responseT = await treinamentoRepository.listarTreinamentos(filterKey)
      this.isLoading = false
      if(responseT.data['success']) {
        this.treinamentos = responseT.data['data'].map(item => {
          item.nome_treinamento = `${item.codigo} - ${item.nome_atividade}`
          return item
        })
      }
    },
    async fetch() {
      try {
        await this.listarTreinamentos()
        await this.listarEmpresas()
        await this.listarFuncionarios()
        this.isLoading = false
      }catch (e) {
        console.log(e)
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Não foi possível buscar os dados básicos de filtro!'
        })
      }finally {
        this.isLoading = false
      }
    },
    TreinamentoSelecionado(treinamento) {
      this.treinamentoSelecionado = treinamento
    },
    EmpresaSelecionada(empresa) {
      this.empresaSelecionada = empresa
    },
    PessoaSelecionada(pessoa) {
      this.pessoaSelecionada = pessoa
    },
    filtrosValidados() {
      // TEM QUE PREENCHER AS DATAS
      if(this.filtro.data_inicio === '' || this.filtro.data_fim === '') {
        this.$swal({
          icon: 'info',
          text: 'Obrigatório informar data inicial e final!'
        })
        return false
      }
      var date1 = moment(this.filtro.data_inicio.split('/').reverse().join('-'))
      var date2 = moment(this.filtro.data_fim.split('/').reverse().join('-'))
      let diffMonths = date2.diff(date1, 'months')

      // A DIFERENÇA DAS DATAS NÃO PODE SER MAIOR QUE UM ANO
      if(diffMonths > 12) {
        this.$swal({
          icon: 'info',
          text: 'A diferença entre as datas não podem ser maior que um ano!'
        })
        return false
      }

      if(diffMonths < 4) return true

      // PELO MENOS A PESSOA, TREINAMENTO OU EMPRESA PRECISA SER SELECIONADO
      if(this.empresaSelecionada == null && this.treinamentoSelecionado == null && this.pessoaSelecionada == null) {
        this.$swal({
          icon: 'info',
          text: 'Selecione pelo menos um treinamento, pessoa ou empresa'
        })
        return false
      }
      return true
    },
    gerarRelatorio() {
      if(!this.filtrosValidados()) {
        return
      }

      let data = {}
      this.dataItems = []
      data.empresa_id = this.empresaSelecionada != null ? this.empresaSelecionada.id : null
      data.treinamento_id = this.treinamentoSelecionado != null ? this.treinamentoSelecionado.id : null
      data.pessoa_id = this.pessoaSelecionada != null ? this.pessoaSelecionada.id : null
      data.data_inicio = this.filtro.data_inicio.split('/').reverse().join('-')
      data.data_fim = this.filtro.data_fim.split('/').reverse().join('-')
      this.isLoading = true
      relatoriosRepository.treinamento(data).then(response => {
        this.isLoading = false
        if(response.data['success']) {
          this.dataItems = response.data['data']
        }
        console.log(this.dataItems)
      }).catch(() => {
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Ocorreu um erro ao gerar o relatório!'
        })
      })
    }
  }
}
</script>

<style>

</style>